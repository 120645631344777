.category-section {
    display: inline-grid;
    grid-template-rows: 18vmax;
    column-gap: 1vw;

    /*grid-gap: 5px;*/
    grid-auto-columns: 18vmax;
    grid-auto-flow: column;
    width: 96vw;
    height: 20vmax;
    margin-top: 3vh;
    margin-left: 2vw;
    margin-right: 2vw;
    overflow-y: hidden;
    overflow-x: auto;
    justify-items: right;
    justify-content: space-evenly;
}

.long {
    justify-content: space-between;
}

.path-label {
    font-size: calc(8px + 0.8vmax);
    text-decoration: underline;
    cursor: pointer;
}

.path-end {
    font-size: calc(8px + 0.8vmax);
}

.mid-bar {
    width: 100vw;
    height: fit-content;
    background-color: rgb(206, 214, 235);

    text-align: center;
}

.ToolDiv {
    display: inline-block;
    margin: 0;
    min-width: 40vw;
    margin-top: 1.5vh;
}

.nav-path {
    display: inline-block;
    text-align: center;
    width: fit-content;
    margin-top: 3vh;
}

.ant-input-search {
    max-width: 25vw;
    max-height: 5vh;
}

.StewardsLink {
    font-size: 1.35vmax;
    margin: -3%;
    display: inline;
}

.WelcomeHolder {
    background-color: rgb(206, 214, 235);
    width: 100%;
    margin-bottom: 2vh;
}

.Instructions {
    font-family: "Verdana";
    font-size: 1.7vmax;
    margin-bottom: -1vh;
}

.WelcomeSection {
    display: block;
    width: 60%;
    margin-left: 20%;
}

.WelcomeText {
    font-family: "Verdana";
    font-size: 1.35vmax;
    display: inline;
}

.Italicized {
    font-style: italic;
    padding: 0;
    margin: 0;
}

.ToggleText {
    color: #0000EE;
    margin: 0;
    font-size: 1.35vmax;
    cursor: pointer;
}

.WelcomeSubTitle {
    font-family: "Georgia";
    font-style: normal;
    font-size: 1.8vmax;
    margin: 0;
    padding-bottom: 4%;
    padding-top: 5%;
}

.Explanation{
    padding-bottom: 2vh;
}

.Explanation p {
    display: inline;
    font-family: "Verdana";
    font-size: 1.35vmax;
    margin: 0;
}

#greenText{
    font-style: bold;
    color: #3A7241;
}

#redText{
    font-style: bold;
    color: #F0163A;
}
