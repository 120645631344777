.page {
    background-color: rgb(245, 245, 253);
    min-height: 100vh;
}

.flex {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-right: 10%;
    margin-left: 3%;
}

.back-button {
    color: black;
    display: block;
    cursor: pointer;
    font-size: calc(10px + 0.6vw);
    margin-left: 5vw;
    margin-bottom: 0;
}

.back-button:hover {
    color: blue;
}

.invasive-tag {
    margin-top: 1vh;
    font-size: calc(14px + 0.6vw);
    font-weight: 500;
    text-align: center;
    width: 80%;
    margin-left: 10%;
    display: inline-block;
    color: #F0163A;
    text-decoration: underline;
    text-decoration-color: #F0163A;
}

.InfoSection {
    width: 32vw;
    margin-left: 5%;
    margin-bottom: 5%;
    vertical-align: top;
    font-size: calc(10px + 0.6vw);
}

.listItem {
    list-style-position: outside;
}

.NameSection {
    width: 32vw;
    margin-right: 10%;
    margin-left: 5%;
    margin-bottom: 5%;
    font-size: calc(10px + 0.6vw);
}

.SectionHead {
    border-bottom: 1px solid black;
}

.slideshow {
    position: relative;
    height: 40vh;
    width: 36vw;
    overflow: hidden;
    white-space: nowrap;
    background-color: rgb(40, 38, 38);
    margin-bottom: 0vh;
}

.slideshow-subscript {
    position: relative;
    width: 80%;
    font-size: 2vmin;

}

.slide {
    display: inline-block;
    position: relative;
    height: 40vh;
    width: 100%;
}

.slide img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.arrow {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.rightArrow {
    position: absolute;
    top: 45%;
    right: 15px;
    z-index: 5;
}

.leftArrow {
    position: absolute;
    top: 45%;
    left: 15px;
    z-index: 5;
}

.left,
.right {
    color: white;
    font-size: 4.5vw;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.dots-container {
    position: absolute;
    z-index: 5;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 5px 0;
}

.dot {
    background: rgb(0, 0, 0);
    display: inline-block;
    padding: 6px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 50%;
    border: solid white 2px;
}

.active {
    background: rgb(255, 255, 255);
}

.expand-box {
    position: fixed;
    z-index: 1000001;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.9);
}

.expand-background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    overflow: auto;
}

.expand-image {
    margin: auto;
    display: block;
    max-width: 100%;
    max-height: 75%;
    margin: auto;
    padding-top: 1.5%;
}

.close {
    position: fixed;
    top: 15px;
    right: 35px;
    color: rgb(255, 255, 255);
    font-size: calc(10px + 2vw);
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
}

.caption,
.title,
.credits {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: calc(10px + 0.8vw);
    max-width: 80vw;
    margin: auto;
    padding-top: 1.5%;
}

.overlay-rightArrow {
    z-index: 15;
    position: absolute;
    top: 45%;
    right: 25px;
}

.overlay-leftArrow {
    z-index: 15;
    position: absolute;
    top: 45%;
    left: 25px;
}

.overlay-left,
.overlay-right {
    color: white;
    font-size: 8vw;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
