.card {
    width: 13vw;
    height: 13vw;
    text-align: center;
    box-shadow: -2px -2px 3px rgba(128, 128, 128, 0.363);
}

.invasive {
    background-color: #F0163A;
}

.non-invasive {
    background-color: #3A7241;
}

.image-holder {
    position: relative;
    width: 13vw;
    height: 9vw;
    background-color: black;
}

.card-image {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

.card-label {
    color: white;
    font-size: 1.2vw;
    margin: 0;
    padding: 0;
}

.long-card-label {
    color: white;
    font-size: 1vw;
    line-height: 120%;
    margin: 0;
    margin-top: 2%;
    padding: 0;
}

.scientific-name {
    margin: 0;
    font-size: 0.8vw;
    color: rgba(255, 255, 255, 0.603);
}

.long-sci-card-label {
    margin: 0;
    font-size: 0.6vw;
    color: rgba(255, 255, 255, 0.603);
}
