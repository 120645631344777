#basicField{
    font-size: 36;
}
#submitButton{
    font-size: 36;
    height: 5vh;
    min-height: 30px;
    width: 20vw;
    min-width: 100px;
    margin: 2.5vw;
    border-radius: 10px;
    
}
.NewUserContents{
    margin: 5%;
    
}