.card-table {
    width: 80vw;
    margin-top: 2vh;
    margin-left: 14.5vw;
    padding-bottom: 5vh;
    display: grid;
    grid-template-columns: 14vw 14vw 14vw 14vw 14vw;
    grid-template-rows: auto;
    row-gap: 1.25vh;
    column-gap: 0.4vw;
    background-color: rgb(245, 245, 253);
}

.card-table a{
    text-decoration: none;
}

.page {
    background-color: rgb(245, 245, 253);
}

.invasive-warning {
    width: 100vw;
    text-align: center;
    margin-top: 2vh;
    margin-bottom: -2vhvh;
    font-size: 1.2vw;
}
