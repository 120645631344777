.check-div {
    border-style: solid;
    border-color: black;
    border-width: 1px;
    min-width: 14vmax;
    height: 5vh;
    border-radius: 12px;
    display: inline-block;
    font-size: 1vmax;
    text-align: center;
    line-height: 5vh;
    margin-right: 1vw;
    margin-bottom: 2vh;
    overflow: ellipse;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.non-invasive-check {
    background-color: #F2F5F1;
    color: #8D6262;
}

.invasive-check {
    background-color: #F0163A;
    color: white;
}

.check {
    margin-right: 5px;
}
