.Inner-popup{
    position: fixed;
    margin: 0 auto;
    width: 50%;
    min-width: 400px;
    height: 60%;
    min-height: 360px;
    top: 20%;
    left: 20%;
    bottom: 0;
    right: 0;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
}

.File-searcher{
    display: block;
    margin: 5% auto;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.Dragged-div{
    background-color: rgb(129, 129, 129);
    height: 30%;
    width: 80%;
    margin: 0 auto;
    margin-top: 2.5%;
    text-align: center;
    border-radius: 10px;
}

.Undragged-div{
    background-color: rgb(233, 233, 233);
    height: 30%;
    width: 80%;
    margin: 0 auto;
    margin-top: 2.5%;
    text-align: center;
    border-radius: 10px;
}

.Drag-and-drop{
    height: 80%;
    width: 80%;
}

.Picture-label{
    display: block;
}

.Long-input{
    width: 95%;
    margin-left: 2%;
}

.Long-input-review{
    padding-top: 2px;
    padding-bottom: 5px;
    background-color: #f1f1f1;
    border-width: 1px;
    border-color: rgb(0, 0, 0);
    min-height: 50px;
    padding-left: 1.5px;
    padding-right: 5px;
    margin-top: 1.5px;
    margin-bottom: 3px;
    max-height: 75px;
    border-style: solid;
    border-color: black; 
    color:rgb(75, 76, 77);
    font-size:15px;
    white-space: pre-wrap;
    overflow: scroll;
}

.Large-area{
    min-width: 95%;
    max-width: 95%;
    min-height: 15%;
    max-height: 15%;
    margin-left: 2%;
}

#Cancel-button{
    margin-top: 2.5%;
    margin-right: 10%;
    float: right;
    width: 35%;
    height: 10%;
    font-size: 100%;
    border-radius: 10px;
}

#Submit-button{
    margin-top: 2.5%;
    margin-left: 10%;
    float: left;
    width: 35%;
    height: 10%;
    border-radius: 10px;
    font-size: 100%;
    background-color: rgb(147, 183, 224);
}

img{
  cursor: pointer;
}
.cutoffExtraText{
    width: 80px;
    overflow: hidden;
    float: right;
    margin-top: 1%;
    margin-right: 1%;
}
