* {
    box-sizing: border-box;
}

.Home {
    height: 100vh;
    min-height: 600px;
    min-width: 300px;
}

#Top-row {
    height: 0.01%;
}

.Change-table {
    width: 100%;
    height: 100%;
}

.Home-body {
    height: 90vh;
    min-height: 540px;
    width: 80vw;
    min-width: 640px;
    padding: 0 auto;
    margin: 0 auto;
    left: 20vw;
    position: absolute;
}

@media all and (max-width: 800px) {

    .Home-body {
        margin-left: 0;
        left: 160px;
    }
}

.Above-table-button {
    width: 47.5%;
    height: 5%;
    margin: 1.25%;
    border-radius: 10px;
}

.Change-table-header {
    border: solid black;
    margin-top: 0;
    color: black;
    padding-top: 0;
    text-align: center;
    background-color: rgba(144, 215, 243, 0.781);
}

.categoryChangeTable {
    width: 100%;
    height: 100%;
}

.Change-table-cell {
    border: solid black;
    margin-top: 0;
    padding-top: 0;
    padding: 1%;
    height: 80%;
    width: 50%;
    text-align: center;
    background-color: rgba(144, 215, 243, 0.781);
}

#Delete-proposal-button {
    width: 60%;
    font-size: 1vw;
    background-color: rgb(231, 231, 231);
}

.Delete-proposal-button {
    position: absolute;
    width: 100%;
    height: 15%;
    float: right;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-top-width: 2px;
    background-color: rgba(231, 141, 141, 0.753);
}

.Change-section {
    height: 90%;
    width: 62.5%;
    margin-right: 2.5%;
    margin-top: 2.5%;
    float: right;
    position: relative;
}

.categoryChangesHolder {
    height: 90%;
    width: 30%;
    margin-left: 1.5%;
    position: relative;
    float: left;
    margin-top: 2.5%;
}

.Unreviewed-card {
    min-height: 100px;
    margin-bottom: 2%;
    float: top;
}

.categoryChangeCard {
    padding: 0;
    margin-bottom: 1%;
    margin-top: 0%;
    position: relative;

    top: 22px;
    width: 100%;
    float: left;
    color: black;
    border-top: grey solid;
    border-right: grey solid;
    border-width: 2px;
    border-right-width: 0;
    background-color: white;
    border: solid black;
    border-radius: 20px;
}

/* There doesn't seem to be reviewed-card class, so all cards are using the unreviewed-card styling.  Don't know why that would be a problem, just odd  */
#Card-holder {
    float: top;
    height: 100%;
    margin-top: 5%;
    overflow: auto;
}

/* I'm planning on replacing the unreviewed-card card with individual cardholder to store the new individual left and right sections  */

.individualCardHolder {
    float: top;
    height: 100%;
    margin-top: 0%;
}

.individualLeftSection {
    height: 100px;
    float: left;
    color: black;
    border-top: grey solid;
    border-right: grey solid;
    border-width: 2px;
    border-right-width: 0;
    background-color: white;
    border: solid black;
    border-radius: 5%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 70%;
}

.individualRightSection {
    width: 30%;
    height: 100px;
    padding: 0;
    float: right;
    color: black;
    border-top: grey solid;
    border-width: 2px;
    border-left-width: 0;
    border: solid black;
    border-radius: 5%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 80%;
}

.Left-section {
    padding: 0;
    position: relative;
    height: 70px;
    top: 22px;
    width: 70%;
    float: left;
    color: black;
    border-top: grey solid;
    border-right: grey solid;
    border-width: 2px;
    border-right-width: 0;
    background-color: white;
    border: solid black;
    border-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.Right-section {
    width: 30%;
    height: 70px;
    padding: 0;
    float: right;
    color: black;
    border-top: grey solid;
    border-width: 2px;
    border-left-width: 0;
    border: solid black;
    border-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 80%;
}

.Update {
    background-color: rgb(218, 236, 149);
}

.Removal {
    background-color: rgb(245, 106, 106);
}

.Addition {
    background-color: rgb(52, 109, 233);
    color: white;
}

#Search-bar {
    width: 90%;
    height: 7%;
    margin-left: 5%;
    margin-bottom: 3%;
    margin-top: 3%;
}

p {
    text-align: center;
    margin: 3%;
}

.Primary-text {
    font-size: 80%;
}

.Secondary-text {
    font-size: 70%;
}

.Tertiary-text {
    font-size: 60%;
    color: grey;
}

.Moved-species {
    font-size: 60%;
}

.Publish-button {
    position: absolute;
    left: 60%;
    bottom: -4px;
    height: 8%;
    width: 30%;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-width: 3px;
    font-size: 16px;
    border-color: black;
    background-color: whitesmoke;
}
