#New-cat {
    height: 100vh;
    width: 100vw;
    min-width: 800px;
    min-height: 600px;
}

.Main-contents {
    position: absolute;
    height: 90vh;
    min-height: 540px;
    width: 80vw;
    min-width: 800px;
    display: inline-block;
}

.Picture-holder {
    position: relative;
    height: 20%;
    width: 14%;
    margin: 0 auto;
    margin-top: 3%;
    left: -20%;
}

.Add-image {
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 20px;
}

#Category-info {
    width: 80%;
    height: 50%;
    margin: 0 auto;
    margin-top: 5%;
}

#Left-input {
    align-self: left;
    margin: 0;
    width: 50%;
}

#Right-input {
    float: right;
    text-align: right;
    margin: 0;
    width: 50%;
}

#Entries-wrapper {
    position: relative;
    width: 100%;
    height: 85%;
}

#Entries-section {
    position: relative;
    font-size: 2em;
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    background-color: white;
}

.Entries-section-left-button {
    position: absolute;
    height: 10%;
    bottom: 5%;
    left: 20%;
}

.Entries-section-right-button {
    position: absolute;
    height: 10%;
    bottom: 5%;
    right: 20%;
    color: black;
}

.Bottom-buttons {
    width: 80%;
    margin: 0 auto;
    position: relative;
}

#Cat-submit-button {
    position: absolute;
    left: 10%;
    height: 8vh;
    min-height: 48px;
    width: 30%;
    border-radius: 10px;
    color: black;
}

#Cat-cancel-button {
    position: absolute;
    right: 10%;
    height: 8vh;
    min-height: 48px;

    width: 30%;
    border-radius: 10px;
}
