.User-table{
    width: 100%;
    margin: 0 auto;
    border: solid black;
    height: 100%;
    background: white;
}

.User-table-holder{
    width: 70%;
    margin: 5% auto;
    overflow: scroll;

}

table{
    border-collapse: collapse;
}

td{
    padding: 5px;
    margin: 0;
    height: 5vh;
    min-height: 30px;
    border-bottom: solid rgb(92, 92, 92) 2px;
}

th{
    border-bottom: solid rgb(92, 92, 92) 2px;
    text-align: left;
    padding: 5px;
    height: 5vh;
    min-height: 30px;

}

#User-dropdown{
    float: left;
    width: 50%;
}

#userButton{    
    min-height: 30px;
    height: 5vh;
    width: 20vw;
    min-width: 80px;
    margin: 2.5vw;
    border-radius: 10px;
}
#smallerButton{
    height: 5vh;
    min-height: 30px;
    width: 10vw;
    min-width: 80px;
    margin: 2.5vw;
    border-radius: 10px;
}

.newUserTag{
    background-color: red;
    color: white;
    border-radius: 10px;
    width: calc(25px + 3vw);
}