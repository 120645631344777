.Management-sort-menu {
    width: 30%;
    height: 50%;
    border-width: 0;
    margin: 2.5%;
    float: left;
    background-color: rgba(255, 255, 255, 0.486);
}

.Manage-Category {
    width: 100%;
    height: 8%;
    font-size: 36px;
    color: black;
    background-color: rgb(160, 160, 160);
}

.SubCategory {
    width: 85%;
    height: 10%;
    margin-left: 15%;
    margin-right: auto;
    font-size: 36px;
    color: white;
    background-color: rgb(160, 160, 160);
}

.OpenCategory {
    height: 100%;
    font-size: 36px;
    color: white;
}

.rightImage {
    position: absolute;
    left: 45%;
    top: 10%;
    width: 45%;
    height: 70%;
    object-fit: contain;
    margin: 0%;
}

.newCategoryPopup {
    position: absolute;
    font-size: 2.5em;
    top: 25%;
    right: 25%;
    height: 50%;
    width: 50%;
    background-color: white;
    z-index: 1;
}

.offTheEdge {
    margin: 1%;
}
