.Footer { 
    width: 100vw;
    padding-left: 12.5vw;
    padding-right: 12.5vw;
    text-align: center;
    padding-top: 3vh;
    padding-bottom: 3vh;
    background-color: rgb(206, 214, 235);
}

.WelcomeText {
    font-family: "Verdana";
    font-size: 1.35vmax;
    margin: 0;
    display: inline;
}

.StewardsLink {
    font-family: "Verdana";
    font-size: 1.35vmax;
    margin: 0%;
    display: inline;
}
