.Side-bar {
    background-color: rgba(144, 215, 243, 0.781);
    padding: 0;
    margin: 0;
    min-width: 160px;
    min-height: 540px;
    width: 20vw;
    height: 90vh;
    float: left;
}

.Side-button {
    height: 8%;
    color: black;
    width: 100%;
    font-size: 16px;
    background-color: rgba(245, 245, 245, 0.747);
}

.Selected-button {
    height: 8%;
    width: 100%;
    color: black;
    background-color: rgba(160, 155, 155, 0.712);
    font-size: 16px;
}
