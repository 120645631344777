#Login {
    display: block;
    text-align: center;
    align-content: center;
    width: fit-content;
    height: 100vh;
    min-height: 800px;
    margin: 0 auto;
    padding-top: 5%;
}

#Login-button {
    width: 75%;
    height: 5%;
}
