#root {
    width: 100vw;
    height: 100vh;
    background-color: rgb(221, 221, 221);
}

.Species-form {
    display: flex;
    height: fit-nt;
    flex-flow: row wrap;
    width: 100%;
    overflow: grow;
}

.Entry-div {
    width: 50%;
    display: block;
}

.Full-div {
    width: 100%;
    float: center;
}

.Text-area {
    width: 80%;
    align-self: center;
    align-content: center;
    float: right;
}

.Cancel-button {
    width: calc(120px + 5%);
    height: 50px;
    margin-right: 5%;
    margin-top: 2%;

    float: right;
    font-size: 16px;
}

.Revise-button {
    position: center;
    width: calc(120px + 5%);
    height: 50px;
    margin: 2%;
    margin-top: 2%;
    margin-left: calc(10px + 2%);
    margin-right: calc(10px + 2%);
    font-size: 16px;
    color: black;
}

.Submit-button {
    width: calc(120px + 5%);
    height: 50px;
    margin: 5%;
    margin-top: 2%;
    margin-left: 4%;
    margin-right: 2%;
    float: left;
    color: black;
    font-size: 16px;
}

.Text-row {
    width: 100%;
}

input {
    margin: 1%;
}

.Small-input {
    margin-left: 5%;
    min-width: 200px;
    height: 20px;
}

.Large-input {
    min-width: 100%;
    min-height: 50px;
    max-height: 75px;
    margin-left: 0;
}

text {
    font-size: 14px;
}

.Picture-frame {
    height: 100%;
    width: 30%;
    min-width: 200px;
    margin: 0;
    float: left;
}

#PicturesPanel {
    height: 25%;
    align-content: center;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: 2%;
}

.Image-section {
    height: 85%;
    position: relative;
}

.Image {
    height: 100%;
    width: 100%;
}

.Image-sectionAdded {
    border: 4px solid green;
}

.Image-sectionRemove {
    border: 4px solid red;
}

.Image-sectionChange {
    border: 4px solid yellow;
}

.keyLayout{
    display: flex;
    flex-direction: row;
}

.highlightKey{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.box {
    float: left;
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
    border: 1px solid black;
    clear: both;
  }
  
.red {
    background-color: red;
}

.green {
    background-color: green;
}

.yellow {
    background-color: yellow;
}

.Delete {
    position: absolute;
    height: 25px;
    width: 25px;
    top: 0;
    right: 0;
    z-index: 12;
}

#LeftArrow {
    position: absolute;
    height: 10%;
    top: 80%;
    left: 30%;
}

#RightArrow {
    position: absolute;
    height: 10%;
    top: 80%;
    right: 30%;
}

.Picture-title {
    position: absolute;
    width: fit-content;
    top: 0%;
    font-size: 80%;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.747);
}

.Picture-credits {
    position: absolute;
    width: fit-content;
    top: 15%;
    font-size: 50%;
    z-index: 10;
    color: rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0.534);
}

#Primary-pic-label {
    position: relative;
    height: 15%;
    width: 200px;
    top: 0;
    padding: 0;
    margin: 0;
    margin-right: 0;
    z-index: 10;
}

#Holder-div {
    width: 200px;
}

#Contents {
    width: 76vw;
    min-width: 608px;
    height: 85vh;
    min-height: 510px;
    margin: 2vw;
    float: right;
    overflow: scroll;
    background-color: rgb(221, 221, 221);
}

.Add-button {
    height: 100%;
    width: 100%;
    font-size: 20px;
}

#Dropdown {
    width: 53%;
    margin-left: 17%;
}

.dd-menu {
    width: 80%;
    float: right;
}

.Highlighted {
    background-color: yellow;
}

.Added {
    background-color: rgba(77, 209, 0, 0.507);
}

.Removed {
    background-color: rgba(255, 0, 0, 0.568);
    text-decoration: line-through;
}

.Field-div{
    min-width: 100%;
}

.review_text_box {
    padding-top: 2px;
    padding-bottom: 5px;
    background-color: #f1f1f1;
    border-width: 1px;
    border-color: rgb(0, 0, 0);
    min-height: 50px;
    padding-left: 1.5px;
    padding-right: 5px;
    margin-top: 1.5px;
    margin-bottom: 3px;
    max-height: 75px;
    border-style: solid;
    border-color: black; 
    color:rgb(75, 76, 77);
    font-size:15px;
    white-space: pre-wrap;
    overflow: scroll;
}


.review_text_line {
    text-align: left;
    background-color: #f1f1f1;
    color: rgb(75,76, 77);
    padding-left: 2px;
    padding-right: 2px;
    border-width: 1px;
    border-color: black;
    border-style: solid;
    margin-top: 5px;
    margin-bottom: 2px;
    display: inline-block;
    width: 50%;
    margin-left: 0px;
}