.TopBar{
    width: 100%;
    vertical-align: middle;
    text-align: left;
    background-color: white;
    border-style: solid;
    border-bottom: 2%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    z-index: 100000;
    top: 0;
}
.BarImage{
    width: 100%;
}
