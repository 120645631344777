
.PopupMainBody{
    position: absolute;
    left: 12%;
    width: 76%;
    height: 66%;
    background-color: lightgrey;
    z-index: 11;
    outline: solid black;
    overflow: scroll;
}
.PopupTopbar{
    position: relative;
    width: 100%;
    left: 0%;
    top: 0%;
    height: 15%;
    background-color: darkgrey;
    z-index: 12;
}
.popupSpeciesCardHolder{
    overflow: scroll;
    top: 15%;
    height: 85%;
    width: 100%;
    min-width: 1000px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    row-gap: 0%;
}
.popupCatalogCard{
    border: solid black;
    border-width: 1px;
    height: 200px;
    width: 90%;
    padding: 0;
    margin-top: 20px;
    background-color: white;
    border-radius: 5px;
    text-align: center;
}

.PopupInfo{
    position: static;
    margin-right: 5%;
    margin-top: 3%;
    width: 40%;
    top: -130%;
    float: right;
    font-size: calc(8px + .8vw);
    float: top;
}
#entryPopupButton{
    height: 5vh;
    min-height: 30px;
    width: 10vw;
    min-width: 80px;
    margin-top: 2vh;
    margin-left: 2vw;
    float: top;
    border-radius: 10px;
}