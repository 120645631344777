.search-button {
    cursor: pointer;
    background-color: rgb(82, 103, 134);
    color: whitesmoke;
    height: 100%;
    width: 28%;
    border-radius: 0 11px 11px 0;
    border: 2px;
    font-size: 1vmax;
    border-color: black;
    border-style: solid;
    font-family: "Verdana";
    font-style: bold;
}

.search-field {
    border: 0;
    width: 70%;
    height: 100%;
    font-size: 1vmax;
    border-radius: 11px 0 0 11px;
    border: 2px;
    border-right: 0;
    border-style: solid;
    text-indent: 1vmax;
    font-family: "Verdana";
}

.search-div {
    display: inline-block;
    height: 5vh;
    width: 25vmax;
}
