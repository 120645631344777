.category-image {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

.first-cat-image {
    height: 110%;
    width: 100%;

    transform: translate(0, 0%);
}

.cat-image-holder {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    height: 77.8%;
    background-color: black;
}

.category-card {
    background-color: rgb(52, 76, 150);
    width: 100%;
    height: 100%;
    margin-top: 0;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    box-shadow: -2px -2px 3px rgba(128, 128, 128, 0.363);
    font-family: "Verdana";
    font-style: bold;
}

.back-card {
    background-color: rgb(52, 76, 150);
    margin-top: 25%;
    margin-right: 12.5%;
    width: 75%;
    height: 50%;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    box-shadow: -2px -2px 3px rgba(128, 128, 128, 0.363);
    font-family: "Verdana";
    font-style: bold;
    border-radius: 8px;
}

.category-text {
    font-size: 1.4vmax;
    color: white;
    line-height: 4vmax;
    text-align: center;
}

.long-cat-text {
    font-size: 0.8vmax;
    color: white;
    line-height: 2vmax;
    text-align: center;
}

.one-line {
    font-size: 1vmax;
    line-height: 4vmax;
}

.other-aquatic {
    color: white;
    font-size: 0.8vmax;
    line-height: 4vmax;
}

.back {
    font-size: calc(8px + 1.2vmax);
    font-style: bold;
    color: white;
    line-height: 9vmax;
    text-align: center;
}

.long-back {
    margin-top: 2.5vmax;
    font-size: calc(8px + 0.1vmax);
    font-style: bold;
    color: white;
    line-height: 2vmax;
    text-align: center;
}
