#Body {
    width: 80%;
    height: 90%;
    float: right;
    background-color: rgb(221, 221, 221);
    padding-left: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 3px;
}

#Log-Container {
   overflow: auto;
   display: flex;
   flex-direction: column-reverse;
   background-color:white;
   height: 100%;

}

.Icon-image {
    height: 100%;
    width: 100%;
    padding: 0;
}

.Right-side {
    position: relative;
    float: right;
    width: 95%;
    height: 100%;
}

.Cards {
    width: 100%;
    height: 87%;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto;
    row-gap: 20px;
    overflow: scroll;
}

.Catalog-card {
    position: relative;
    display: inline-block;
    border: solid black;
    border-width: 1px;
    height: 100px;
    width: 95%;
    padding: 0;
    background-color: white;
    border-radius: 5px;
}

.Info-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(221, 221, 221);
}

.Top-right-label {
    position: absolute;
    text-align: center;
    line-height: 200%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 25%;
    width: 35%;
    top: 0%;
    right: 0%;
    font-size: 80%;
    background-color: rgb(190, 190, 190);
}

.Modified {
    color: rgb(153, 153, 12);
}

.Deleted {
    color: rgb(190, 9, 9);
}

.Added {
    color: rgb(40, 40, 167);
}

.Delete-button {
    width: 15%;
    max-width: 25px;
    max-height: 25px;
    padding: 0;
    font-size: 8px;
    border-width: 0;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    float: right;
}

.Edit-button {
    width: 15%;
    max-width: 25px;
    max-height: 25px;
    padding: 0;
    font-size: 8px;
    border-width: 0;
    float: right;
    margin-top: 5px;
}

.Card-contents {
    height: 100%;
}

.Invasive {
    background-color: rgb(224, 190, 190);
    color: rgb(27, 16, 16);
}

.Sci-name-label {
    position: absolute;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 25%;
    width: 55%;
    top: 5%;
    font-size: 60%;
}

.Name-label {
    position: absolute;
    bottom: 30%;
    left: 10%;
    width: 80%;
    font-size: 70%;
}

.Sorting-bar {
    width: 98%;
    height: 6%;
    min-width: 610px;
    min-height: 40px;
    margin: 2.5%;
    margin-left: 0%;
    border-radius: 4px;
    z-index: 1;
    overflow: hidden;
    background-color: rgb(201, 201, 201);
}

.Sort-bar-label {
    margin-top: 45%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    font-size: 1.4vw;
    color: black;
}

#Sort-by-dropdown {
    height: 90%;
    width: 16em;
}

#New-entry-button {
    width: 18vw;
    height: 90%;
    font-size: 1em;
    margin-left: 2%;
    border-radius: 10px;
    background-color: rgb(136, 210, 241);
    color: black;
}

.Card-link {
    color: black;
}

.log {
    white-space: pre-line;
    text-align: left; 
    font-size: 15px;
}
