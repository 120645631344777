.moveEntryPopup{
    position: absolute;
    left: 22%;
    width: 60%;
    height: 80%;
    top: 11%;
    background-color: black;
    z-index: 1;
    font-size: .5em;

}
#Entries-section-edit{
    position: relative;
    font-size: 2em;
    max-width: 100%;
    min-width: 100%;
    min-height: 80%;
    max-height: 80%;
    columns: 2;
    background-color: white;
    overflow: scroll;
}
.memberSpecies{
    text-align: left;
}
.invasiveImageHolder{
    position: absolute;
    right: 25%;
    top: 0%;
    height: 20%;
    width: 14%;
    margin: 0 auto;
    margin-top: 3%;
}