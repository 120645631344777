.Popup{
    position: fixed;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(128, 128, 128, 0.425);
    color: rgb(255, 251, 0);
    z-index: 20;
}
.Centered-popup{
    position: fixed;
    margin: 0 auto;
    width: 30%;
    min-width: 240px;
    height: 30%;
    min-height: 180px;
    top: 25%;
    font-size: 2vh;
    text-align: center;
    left: 25%;
    bottom: 25%;
    right: 25%;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;
}
.NewUserPopup{
    position: fixed;
    margin: 0 auto;
    width: 30%;
    min-width: 240px;
    height: 30%;
    min-height: 180px;
    top: 25%;
    font-size: calc(10px + 1vw);
    text-align: left;
    left: 25%;
    bottom: 25%;
    right: 25%;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 10px;

}