.TopCategory {
    height: 8%;
    width: 100%;
    font-size: 24px;
    background-color: rgb(50, 50, 200);
    float: right;
    color: white;
}

.lvl2Category {
    height: 8%;
    width: 90%;
    font-size: 24px;
    background-color: rgb(60, 60, 220);
    float: right;
    color: white;
}

.lvl3Category {
    height: 8%;
    width: 80%;
    font-size: 18px;
    background-color: rgb(80, 80, 238);
    float: right;
    color: white;
}

.lvl4Category {
    height: 8%;

    width: 70%;
    font-size: 18px;
    background-color: rgb(90, 90, 255);
    float: right;
    color: white;
}

.WholeSystem {
    height: 53.5%;
    width: 35%;
    float: left;
}

.Selector {
    width: 65%;
    height: 20px;
}

.AddCategory {
    height: 8%;

    width: 70%;
    font-size: 36px;
    background-color: rgb(200, 200, 200);
    float: right;
}

.Category-container {
    position: relative;
}

.selectedCategoryName {
    position: static;
    width: 100%;
    text-align: center;
    font-size: 2em;
}

.editCategory {
    position: absolute;
    left: calc(35% + 250px);
    width: 200px;
    height: 36px;
    font-size: 1.5em;
    border-radius: 12px;
    top: 92%;
    color: black;
}

.newCategory {
    position: absolute;
    width: 200px;
    height: 36px;
    left: calc(20% + 100px);
    font-size: 1.5em;
    border-radius: 12px;
    top: 92%;
    color: black;
}
